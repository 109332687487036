<template>
    <div class="content" v-html="content">
    </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
        content: ""
    }
  },
  mounted() {
      var num = this.$route.query.num;
      console.log("agreement num=" + num);
      var that = this;
      var  request = new XMLHttpRequest();
                    request.onload = function() {
                        console.log("android version = " + request.responseText);
                        if(request.status == 200) {
                           that.content = request.responseText;
                        }
                    }
                    var url = "https://api.honnverse.com/common/agreement/content?code=pokedo_yhxy";
                    if(num == 2) {
                      url = "https://api.honnverse.com/common/agreement/content?code=pokedo_yszc";
                    }
                    request.open('get', url);
                    request.send(null);
  },
}
</script>

<style scoped>
.content {
  text-align: left;
}
</style>